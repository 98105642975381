import React from 'react';
import Layout from '../components/layout';
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from 'react-i18next';
import Seo from '../components/seo';

const AboutUsPage = (location) => {
    const { t } = useTranslation();

    return (
        <Layout location={location}>
            <Seo
                title={`${t('AboutUs')}`}
                description={t('AboutUsMetaDescription')}
            />
            <div className="c-block c-block--first">
                <div className="site-width">
                    <h2 className="c-block__title">{t('AboutAutohunt')}</h2>
                    <div className="c-block__content c-block__content--about-us">
                        <div className="about-us-text">
                            <p>
                                {t('AboutAutohuntPart1')}
                            </p>
                            <p>
                                {t('AboutAutohuntPart2')}
                            </p>
                            <StaticImage
                                style={{ marginTop: '32px', marginBottom: '32px' }}
                                src="../images/signature.png" alt="Axel Dejager" />
                            {/* <StaticImage
                                style={{ marginTop: '32px' }}
                                width={150}
                                height={150}
                                src="../images/Axel Dejager.jpeg" alt="Axel Dejager" /> */}
                        </div>
                        <div className="about-us-image">
                            <StaticImage
                                // width={450}
                                src='../images/autohunt-autoscout.jpg' />
                        </div>

                    </div>
                </div>
                {/* <div className="site-width" style={{ display: 'flex' }}>
                    <div style={{
                        width: '50%',
                        fontSize: '56px'
                    }}>
                        <p>Autohunt - Axel Dejager</p>
                        <StaticImage
                            style={{marginTop: '32px;'}}
                            width={150}
                            height={150}
                            src="../images/Axel Dejager.jpeg" alt="Axel Dejager" />
                    </div>
                    <div style={{
                        'width': '50%'
                    }}>
                        <p>Autohunt focuses on the purchase and sale of young and high-quality second-hand cars. We are also happy to help you in your search for a specific car.
                            Axel Dejager founded Autohunt out of passion for cars and with the aim of guiding you as best as possible in your search for the perfect car. After studying Automotive Management and Automotive Engineering, Axel started his career at BMW. It started at the bank of the brand, later he went to work at an official BMW concession as a seller of young used cars and as a stock manager. He decided to start his own business in Ingelmunster where he offers a varied range of Used cars.</p>
                    </div>
                </div> */}
            </div>

            <div className="c-block parallax-group c-block--movie" style={{ overflow: 'hidden', position: 'relative' }}>
                <StaticImage
                    className="parallax__layer--back"
                    src="../images/Tesla - greyscale.jpeg" alt="Axel Dejager"
                    style={{
                        position: 'absolute',
                        zIndex: -1,
                        width: '100%',
                        top: 0,
                        height: '100%'
                    }} />
                <div className="site-width parallax__layer--base" style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="video-container">
                        <iframe src="https://www.youtube.com/embed/q7n2NRtn0ws" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
            <div className="c-block">
                <div className="site-width" style={{ display: 'flex' }}>
                    <div className="c-block__content c-block__content--guarantee">
                        <div class="autohunt-guarantee">
                            <h2>{t('TheAutohuntWarranty')}</h2>
                            <ol class="c-service-list c-service-list--numbers" style={{ flexDirection: 'column' }}>
                                <li class="c-service-list__item c-service-list__item--large-number c-service-list__item--large-number--one">
                                    <div>
                                        <span class="service-item__title" style={{ paddingTop: '0px', }}>{t('CarPass')}</span>
                                        <p class="service-item__description">{t('CarPassDescription')}</p>
                                    </div>
                                </li>
                                <li class="c-service-list__item c-service-list__item--large-number c-service-list__item--large-number--two">
                                    <div>
                                        <span class="service-item__title">{t('AutohuntCheckup')}</span>
                                        <p class="service-item__description">{t('AutohuntCheckupDescription')}</p>
                                    </div>
                                </li>
                                <li class="c-service-list__item  c-service-list__item--large-number c-service-list__item--large-number--three">
                                    <div>
                                        <span class="service-item__title">{t('OneYearWarranty')}</span>
                                        <p class="service-item__description">{t('OneYearWarrantyDescription')}</p>
                                    </div>
                                </li>
                            </ol>
                        </div>
                        <div class="autohunt-guarantee-image" style={{ display: 'flex', alignItems: 'center', margin: '32px' }}>
                            <StaticImage
                                src="../images/De Autohunt Garantie.jpeg" alt="Autohunt garantie" />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AboutUsPage

export const query = graphql`
query ($language: String!){
        locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
            node {
              ns
              data
              language
            }
          }
        }
      }
`